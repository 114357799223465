.spinner .IconSpinner {
  fill: var(--primary2);
  width: 20px;
  height: 20px;
  animation: Spinner-ring 1.5s linear infinite;
  display: block;
}

/* inline */
.spinner--inline {
  display: inline;
  position: relative;
}
.spinner--inline::after {
  content: "\00a0";
  width: 20px;
  display: inline-block;
}
.spinner--inline .IconSpinner {
  position: absolute;
  height: 100%;
  display: inline-block;
}

/* centered */
.spinner--centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  text-align: center;
  padding: 1rem 0;
}
.spinner--centered .IconSpinner {
  margin-left: auto;
  margin-right: auto;
}

/* padded */
.spinner--padded {
  display: block;
  width: 20px;
  padding: 1rem 0;
}
.spinner--padded .IconSpinner {
  margin-left: auto;
  margin-right: auto;
}

/* full page */
.spinner--page {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* fixed */
.spinner--fixed {
  background: var(--primary2);
  color: var(--text-on-primary);
  position: fixed;
  z-index: 99999999;
  top: 10px;
  right: 10px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  animation: fade-in 450ms var(--bezier1) forwards;
}
.spinner--fixed > span {
  margin-right: 5px;
}
.spinner--fixed .IconSpinner {
  fill: var(--text-on-primary);
}
.spinner--fixed.shown {
  display: flex;
}
.spinner--fixed.closing {
  animation: fade-out 450ms var(--bezier1) forwards;
}

/* big */
.spinner--big {
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  height: var(--fullheight);
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  justify-content: center;
  align-items: center;
}
.spinner--big.show {
  display: flex;
  animation: fade-in 300ms forwards;
}
.spinner--big.closing {
  animation: fade-out 200ms forwards;
}
.spinner--big .spinner-container {
  display: block;
  background: var(--background);
  border-radius: 50px;
  padding: 7px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}
.spinner--big .IconSpinner {
  display: block;
  width: 30px;
  height: 30px;
  fill: var(--primary2);
}

/* placeholder */
.spinner--placeholder {
  box-sizing: border-box;
  margin-bottom: 20px;
}
.spinner--placeholder__container {
  height: 47px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}

/* material */
.MaterialSpinner .spinner-svg {
  animation: Spinner-ring 2s linear infinite;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  display: block;
}
.MaterialSpinner .path {
  stroke: var(--primary1);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes Spinner-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fade-in-spinner {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
