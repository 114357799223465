.Modal {
  outline: none;
  opacity: 0;
  transition: opacity 200ms, backdrop-filter 300ms;
  will-change: opacity;
  position: fixed;
  z-index: 1998;
  overflow: auto;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--fullheight);
  background: var(--darken-darker);
  display: block;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  padding-bottom: 0;
  box-sizing: border-box;
}

.Modal h2 {
  box-sizing: border-box;
  padding-right: 30px;
}

.Modal:after {
  content: "";
  display: block;
  padding: 20px;
}

.Modal .modal-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  left: calc((100vw - 100%) / 2);
  background: var(--background);
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.ie .Modal .modal-wrapper {
  animation: none;
}

.ie .Modal.closing .modal-wrapper {
  animation: none;
}

.Modal .modal-actions {
  display: flex;
  justify-content: flex-start;
}

.Modal .modal-actions-centered {
  justify-content: center;
}

.Modal .modal-actions > * {
  margin-right: 10px;
  margin-bottom: 0;
}

.Modal .modal-actions > *:last-child {
  margin-right: 0;
}

.Modal .close-button {
  position: absolute;
  z-index: 5;
  top: 15px;
  right: 25px;
  height: 30px;
  margin-left: auto;
  margin-right: 0;
  text-align: center;
  width: 30px;
  cursor: pointer;
}

.Modal .minimize-button {
  font-size: 2rem;
  font-weight: bold;
}

.Modal .close-button svg {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.Modal .close-button:hover svg path:last-child {
  fill: black;
}

.Modal .close-button svg path:last-child {
  fill: rgb(133, 133, 133);
}

.Modal .modal-inner {
  cursor: default;
  position: relative;
}

.Modal .form-error {
  margin-bottom: 10px;
}

.ie .Modal .modal-inner {
  background: white;
  border-radius: 10px;
}

.Modal .modal-inner > *:not(.modal-actions) {
  padding: 2.5rem;
  padding-top: 1.5rem;
  padding-bottom: 0;
}

.Modal .modal-inner > .modal-actions {
  position: relative;
  z-index: 10;
  padding: 2rem 2.5rem;
}

.Modal .modal-wrapper ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 20px;
}

.Modal .modal-wrapper ::-webkit-scrollbar-track {
  border-radius: 10px;
  border: solid 4px transparent;
  box-shadow: inset 0 0 10px 10px var(--grey-semifaint) !important;
}

.Modal .modal-wrapper ::-webkit-scrollbar-thumb {
  cursor: pointer;
  box-shadow: inset 0 0 10px 10px var(--grey-lighter);
  border: solid 4px transparent;
  border-radius: 10px;
  overflow: hidden;
}

.Modal .modal-wrapper::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 10px 10px var(--grey-darker);
}

.Modal .modal-wrapper .modal-inner > *:not(.modal-actions) {
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
}

@keyframes slide-down {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .Modal {
    padding: 10px;
  }

  .Modal:after {
    content: none;
  }

  .Modal .Radio {
    margin-left: 0;
  }

  .Modal .modal-inner > * {
    padding: 20px;
    box-sizing: border-box;
  }

  .Modal .modal-inner > *:not(.modal-actions) {
    padding: 20px;
  }

  .Modal .modal-inner > .modal-actions {
    padding: 20px;
  }

  .Modal .modal-wrapper .modal-inner > *:not(.modal-actions) {
    max-width: initial;
  }

  .Modal .InputField .input {
    width: 100%;
  }

  .Modal .checkbox-field {
    margin-left: 0;
  }

  .modal:after {
    content: "";
    display: block;
    padding: 50px;
  }

  .Modal .modal-wrapper {
    width: 100% !important;
    left: 0 !important;
    bottom: 0px !important;
    margin-bottom: 0;
    border-radius: 10px;
    animation: slide-in 250ms;
  }

  .Modal .modal-wrapper .flex-row {
    width: 100%;
    justify-content: flex-start;
  }

  .Modal .modal-wrapper .modal-inner > * {
    width: 100% !important;
  }

  .Modal .modal-wrapper .flex-row > *:not(:first-child) {
    margin-left: 10px;
  }

  .Modal .InputDate,
  .Modal .Input,
  .Modal .InputSelect {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: flex-start;
    width: 100% !important;
  }

  .Modal .InputDate .label,
  .Modal .Input .label,
  .Modal .InputSelect .label {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .Modal .InputSelect .select {
    width: 100% !important;
    display: block !important;
    flex-basis: auto !important;
  }

  .Modal .InputDate .input-container,
  .Modal .Input .input-container {
    width: 100% !important;
  }

  .Modal .label {
    text-align: left !important;
    width: 100% !important;
    display: block !important;
  }

  .Modal h2 {
    width: 100%;
  }

  .Modal .modal-actions {
    flex-direction: column;
  }

  .Modal .modal-actions > * {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .Modal .modal-actions > *:last-child {
    margin-bottom: 0 !important;
  }

  .Modal .btn:not(:last-child) {
    margin-bottom: 10px !important;
  }

  .Modal .caption {
    display: block;
    padding: 5px 0px;
  }

  .Modal .JobField {
    display: block;
  }
}

.modal-fade {
  opacity: 0;
}

.modal-fade-enter {
  opacity: 0;
}
.modal-fade-enter-active {
  opacity: 1;
}
.modal-fade-enter-done {
  opacity: 1;
}
.modal-fade-exit {
  opacity: 1;
}
.modal-fade-exit-active {
  opacity: 0;
}
/* 
// affects zindex context?
@media (min-width: 768px) {
  .modal-fade-enter-active {
    backdrop-filter: grayscale(0.7);
  }
  .modal-fade-enter-done {
    backdrop-filter: grayscale(0.7);
  }
  .modal-fade-exit {
    backdrop-filter: grayscale(0.7);
  }
} */

/* 
.modal-fade > .modal-wrapper {
  top: -20px;
}

.modal-fade-enter > .modal-wrapper {
  top: -20px;
}
.modal-fade-enter-active > .modal-wrapper {
  top: 0px;
}
.modal-fade-enter-done > .modal-wrapper {
  top: 0px;
}
.modal-fade-exit > .modal-wrapper {
  top: 0px;
}
.modal-fade-exit-active > .modal-wrapper {
  top: -20px;
} */

@keyframes slide-in {
  0% {
    top: 50px;
  }
  100% {
    top: 0;
  }
}
