$offsets: (
  "zero": 0,
  "tiny": 5px,
  "small": 10px,
  "15": 15px,
  "normal": 20px,
  "large": 30px,
  "huge": 40px,
  "giant": 50px,
  "enourmous": 60px,
  "gigantic": 100px,
  "xboxhueg": 200px,
  "zero-n": -0,
  "tiny-n": -5px,
  "small-n": -10px,
  "15-n": -15px,
  "normal-n": -20px,
  "large-n": -30px,
  "huge-n": -40px,
  "giant-n": -50px,
  "enourmous-n": -60px,
  "gigantic-n": -100px,
  "xboxhueg-n": -200px,
);

.m-h-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.m-v-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.m-t-auto {
  margin-top: auto !important;
}
.m-b-auto {
  margin-bottom: auto !important;
}
.m-l-auto {
  margin-left: auto !important;
}
.m-r-auto {
  margin-right: auto !important;
}

@each $offsetName, $offsetValue in $offsets {
  .m-b-#{$offsetName} {
    margin-bottom: $offsetValue !important;
  }
  .m-t-#{$offsetName} {
    margin-top: $offsetValue !important;
  }
  .m-l-#{$offsetName} {
    margin-left: $offsetValue !important;
  }
  .m-r-#{$offsetName} {
    margin-right: $offsetValue !important;
  }
  .p-b-#{$offsetName} {
    padding-bottom: $offsetValue !important;
  }
  .p-t-#{$offsetName} {
    padding-top: $offsetValue !important;
  }
  .p-l-#{$offsetName} {
    padding-left: $offsetValue !important;
  }
  .p-r-#{$offsetName} {
    padding-right: $offsetValue !important;
  }
}
