@font-face {
  font-family: "Colfax";
  src: url("ColfaxWebLight.eot");
  src: url("ColfaxWebLight.eot?#iefix") format("embedded-opentype"),
    url("ColfaxWebLight.woff2") format("woff2"),
    url("ColfaxWebLight.woff") format("woff");
  font-weight: 200; /* для Light должен быть указан 300 */
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: url("ColfaxWebLightItalic.eot");
  src: url("ColfaxWebLightItalic.eot?#iefix") format("embedded-opentype"),
    url("ColfaxWebLightItalic.woff2") format("woff2"),
    url("ColfaxWebLightItalic.woff") format("woff");
  font-weight: 200; /* для Light должен быть указан 300 */
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: url("ColfaxWebMedium.eot");
  src: url("ColfaxWebMedium.eot?#iefix") format("embedded-opentype"),
    url("ColfaxWebMedium.woff2") format("woff2"),
    url("ColfaxWebMedium.woff") format("woff");
  font-weight: bold; /* указан bold(700) для meduim(500). Правильно meduim(500) */
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: url("ColfaxWebMediumItalic.eot");
  src: url("ColfaxWebMediumItalic.eot?#iefix") format("embedded-opentype"),
  url("ColfaxWebMediumItalic.woff2") format("woff2"),
  url("ColfaxWebMediumItalic.woff") format("woff");
  font-weight: bold; /* указан bold(700) для meduim(500). Правильно meduim(500) */
  font-style: italic;
}

@font-face {
  font-family: "Colfax";
  src: url("ColfaxWebRegular.eot");
  src: url("ColfaxWebRegular.eot?#iefix") format("embedded-opentype"),
    url("ColfaxWebRegular.woff2") format("woff2"),
    url("ColfaxWebRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Colfax";
  src: url("ColfaxWebRegularItalic.eot");
  src: url("ColfaxWebRegularItalic.eot?#iefix") format("embedded-opentype"),
    url("ColfaxWebRegularItalic.woff2") format("woff2"),
    url("ColfaxWebRegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "dotsfont";
  src: url("dotsfont.eot");
  src: url("dotsfont.eot?#iefix") format("embedded-opentype"),
    url("dotsfont.woff") format("woff"), url("dotsfont.ttf") format("truetype"),
    url("dotsfont.svg#dotsfontregular") format("svg");
}
