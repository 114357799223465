.Downloads {
  align-items: flex-end !important;
  background: transparent !important;
  pointer-events: none !important;
}

.Downloads::after {
  content: none !important;
}

.Downloads .modal-wrapper {
  margin-bottom: 1rem !important;
  margin-right: 2rem !important;
}

.Downloads {
  pointer-events: none !important;
}

.Downloads .modal-content {
  pointer-events: all !important;
  padding: 1rem !important;
  padding-bottom: 0.5rem !important;
}

.Downloads .downloads-container {
  width: 450px;
}

.Downloads .title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.Downloads .preparing {
  animation: unpoof 500ms var(--bezier1) forwards;
}

.Downloads .preparing-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Downloads .preparing-container,
.Downloads .progress-container {
  padding-top: 6px;
  position: relative;
}

.Downloads .spinner {
  margin-right: 10px;
  margin-left: 2px;
  position: relative;
  bottom: 1px;
}

.Downloads .progress {
  height: 38px;
  position: relative;
  opacity: 1;
  transform: scale(1);
  transition: opacity 400ms var(--bezier1), height 500ms var(--bezier1),
    transform 500ms var(--bezier1);
  will-change: height, opacity, transform;
}

.Downloads .text {
  padding: 6px 15px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

.ie11 .Downloads .text {
  color: var(--text) !important;
}

.Downloads .text .size {
  white-space: nowrap;
  word-break: keep-all;
  font-weight: bold;
}

.Downloads .text .filename {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: normal;
  word-wrap: normal;
  white-space: nowrap;
}

.Downloads .btn-download {
  height: 19px;
}

.Downloads .btn-download svg {
  position: relative;
  top: -2px;
}

.Downloads .btn-download path:last-child {
  fill: black;
}

.Downloads .bar {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: calc(100% - 6px);
  box-sizing: border-box;
  background: var(--grey-faint);
  border-radius: 25px;
}

.Downloads .bar .received {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: width 1200ms var(--bezier1);
  will-change: width;
  border-radius: 25px;
}

@keyframes unpoof {
  0% {
    height: 0;
    transform: scale(0.5) translateY(-6px);
    opacity: 0;
  }
  100% {
    height: 38px;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes downloads-close {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .Downloads .downloads-container {
    width: 100%;
  }

  .Downloads .modal-wrapper {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
}
