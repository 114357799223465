@import "../fonts/font-styles.css";
@import "./flex-blocks.css";

:root {
  /* normal greys - become light in dark mode */
  /* placeholder, secondary buttons */
  --grey: #acacac;
  /* input icon buttons */
  --grey-darker: rgba(116, 116, 116, 0.906);
  /* border */
  --grey-lighter: rgba(167, 167, 167, 0.5);
  /* table odd row */
  --grey-faint: rgba(138, 138, 138, 0.1);
  /* spacer line  */
  --grey-semifaint: rgba(138, 138, 138, 0.2);
  /* hover effects */
  --grey-semifaint-lighter: rgba(168, 168, 168, 0.1);
  /* active effects */
  --grey-semifaint-darker: rgba(51, 51, 51, 0.2);

  /* input hover effects - must be opaque due to dropdown slide-from-behind animation */
  --grey-hover-light: #fcfcfc;
  --grey-hover-dark: rgb(32, 45, 51);

  /* transparent black "greys" - stay dark in dark mode */
  --darken: rgb(0, 0, 0, 0.2);
  --darken-darker: rgb(0, 0, 0, 0.4);
  --darken-lighter: rgba(0, 0, 0, 0.15);
  --darken-faint: rgb(0, 0, 0, 0.075);
  --darken-fainter: rgb(0, 0, 0, 0.05);

  /* transparent white "greys" - stay light in light mode */
  --lighten: rgb(255, 255, 255, 0.2);

  /* TOO MANY GREYS? */

  --background-dark: #191d20;
  --background-light: #ffffff;
  --background: var(--background-light);

  --background-elements-dark: #1c282c;
  --background-elements-light: var(--background-light);
  --background-elements: var(--background-elements-light);

  --panel-dark: #131518;
  --panel-light: #fbfaf8;
  --panel: var(--panel-light);
  --panel-border: var(--darken-lighter);

  --border-dark: #0e1013;
  --border-light: var(--grey-lighter);
  --border: var(--border-light);
  --border-separator: var(--grey-semifaint);

  --background-collapsable-light: var(--darken-fainter);
  --background-collapsable-dark: var(--darken);
  --background-collapsable: var(--background-collapsable-light);
  --background-collapsable-title: transparent;

  /* for navbar active link gradient UNUSED*/
  --background-faint-dark: rgba(0, 0, 0, 0);
  --background-faint-light: rgba(255, 255, 255, 0);
  --background-faint: var(--background-faint-light);

  --accent-light: #bfe2df;
  --accent-faint-light: #c7ece9;
  --accent-dark: rgba(96, 112, 136, 0.25);
  --accent-faint-dark: rgba(94, 114, 139, 0.15);
  --text-on-accent-dark: #ffffff;

  --primary1-light: #81818130;
  --primary2-light: #81818130;
  --primary3-light: #81818130;
  --primary1-dark: #81818130;
  --primary2-dark: #81818130;
  --primary3-dark: #81818130;

  --primary1: var(--primary1-light);
  --primary2: var(--primary2-light);
  --primary3: var(--primary3-light);

  /* backward compatibility */
  --orange1: var(--primary2);
  --orange2: var(--primary1);
  --orange3: var(--primary3);

  --accent: var(--accent-light);
  --accent-faint: var(--accent-faint-light);
  --accent-bold-light: #df5500;
  --accent-bold-dark: var(--content-bright-inverse);
  --accent-bold: var(--accent-bold-light);

  --white: #fff;
  --black: #0d0e11;
  --danger: #d95d5d;
  --success: #4eca96;

  --content: #555;
  --content-inverse: #bbb;
  --content-bright: #333;
  --content-bright-inverse: #fff;

  --text: var(--content);
  --text-bold: var(--content-bright);
  --text-on-primary: var(--content-bright-inverse);
  --text-on-primary1: var(--content-bright-inverse);
  --text-on-primary2: var(--content-bright-inverse);
  --text-on-primary3: var(--content-bright-inverse);
  --text-on-primary-bold: var(--content-bright-inverse);
  --text-on-accent: var(--content-bright);
  --text-on-accent-faint: var(--content-bright);

  --text-on-grey: #fff;
  --text-on-danger: #fff;

  /* danger semitransparent */
  --error-plank: rgba(217, 93, 93, 0.145);

  /* background -2 */
  --input-hover: var(--grey-hover-light);

  --job-menu: var(--grey-semifaint-lighter);
  --job-menu-disabled: var(--darken-lighter);
  --job-menu-border: var(--darken-faint);
  --job-menu-hover: var(--darken-fainter);
  --job-menu-active: var(--darken);

  --sidebar-group-background: rgb(0, 0, 0, 0.04);
  --sidebar-hover-color: rgba(0, 0, 0, 0.02);

  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;

  --fullheight: calc(var(--vh, 1vh) * 99.99);

  --bezier1: cubic-bezier(0.18, 1.01, 0.46, 0.98);
  --bezier2: cubic-bezier(0.12, 0.63, 0.16, 0.98);
  --bezier3: cubic-bezier(0.23, 1, 0.32, 1);
  --bezier4: cubic-bezier(0.34, 0, 0, 0.99);
  --bezier-transition-wrapper: cubic-bezier(0.31, 0.52, 0, 1);

  --shadow1: 0 3px 10px rgba(0, 0, 0, 0.08);
  --shadow2: 0 1px 10px rgba(0, 0, 0, 0.2);
  --shadow3: 0 3px 10px rgba(0, 0, 0, 0.2);

  --radius-card: 10px;

  --sidebar-width: 300px;
  --sidebar-width-compact: 50px;
}

body * {
  -webkit-tap-highlight-color: transparent;
}

html {
  background: var(--background);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Colfax", sans-serif;
  color: var(--text);
}

b {
  font-weight: bold;
}

em {
  color: var(--primary1);
}

button,
a {
  font-family: "Colfax", sans-serif;
}

input,
textarea {
  font-family: "Colfax", sans-serif;
  color: var(--text);
  border: 1px solid var(--border);
  outline: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: box-shadow 500ms var(--bezier1);
}

input::-ms-clear {
  display: none;
}

input::-webkit-search-cancel-button {
  display: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--grey-lighter);
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: var(--grey);
}

input:focus,
textarea:focus {
  box-shadow: 0 0 0 1px var(--primary2);
  border-color: var(--primary2);
}

.input-label {
  display: inline-block;
  padding: 5px 3px;
}

button > svg {
  pointer-events: none;
  vertical-align: middle;
}

#root {
  display: flex;
}

h1 {
  font-size: 3.6rem;
  margin-bottom: 1.8rem;
  color: var(--primary1);
}

h2 {
  color: var(--primary1);
  font-weight: lighter;
  font-size: 2rem;
  margin-bottom: 20px;
}

h3 {
  color: var(--text-bold);
  font-weight: lighter;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

h5 {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 7px;
  color: var(--primary1);
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.6rem;
  }
  h3 {
    width: 100%;
    font-size: 1.3rem;
  }
  h4 {
    width: 100%;
  }
}

a {
  color: var(--primary1);
  cursor: pointer;
}

a:hover,
a:active {
  color: var(--primary3);
}

a.small {
  font-size: 0.75rem;
}

p {
  line-height: 1.5;
  margin-bottom: 1rem;
}

p.hint {
  line-height: 1.5rem;
  color: var(--text);
  margin-bottom: 1.5rem;
}

p.hint a {
  color: var(--primary2);
  transition: color 200ms;
  text-decoration: none;
}

p.hint a:hover {
  color: var(--primary1);
}

p.hint a:active {
  color: var(--primary3);
}

div.load-more,
a.load-more,
button.load-more {
  color: var(--primary1);
  display: block;
  text-align: center;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.centered-block {
  justify-self: center;
  margin: auto;
  align-self: center;
  padding: 16px 32px;
  max-width: 726px;
  box-sizing: border-box;
}

.centered-block > h2 {
  text-align: center;
}

.centered-block p {
  margin-bottom: 1.4rem;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 576px) {
  .centered-block {
    border: 0;
    padding: 16px 32px;
  }
}

@media (max-width: 768px) {
}

@media (min-width: 1640px) {
}

.container {
  max-width: 1340px;
  width: 100%;
}

.link-btn {
  background: var(--primary1);
  color: var(--text-on-primary);
  padding: 0.75rem 2rem;
  text-decoration: none;
  border-radius: 1rem;
  white-space: nowrap;
  font-size: 1.1rem;
  transition: all 0.2s var(--bezier1);
}

.link-btn:hover {
  background: var(--primary2);
  color: var(--text-on-primary-bold);
}

.link-btn:active {
  background: var(--primary3);
  color: var(--text-on-primary-bold);
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled {
  pointer-events: none;
  color: var(--grey-lighter);
  cursor: default !important;
}

.disabled:hover {
  background-color: var(--white) !important;
}

input.form-control,
textarea.form-control,
.DayPickerInput input,
select.form-control {
  background: var(--background-elements);
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.1rem;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
}

select.form-control {
  background: #fff;
}

.centered {
  text-align: center;
}

.small-text {
  font-size: 0.8rem;
}

th {
  font-weight: bold;
}

table {
  border-spacing: 0 0.75rem;
  border-collapse: separate;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.v-checkbox.v-checkbox-exempt > input ~ label::after {
  display: block;
}

.table td {
  border: 1px solid var(--border);
  padding: 11px;
  line-height: 1.4rem;
}

.table thead td {
  background: var(--primary1);
  color: var(--text-on-primary);
  font-size: 1rem;
}

.assets-table tbody tr,
.accounts-table tbody tr,
.account-makers-table tr,
.account-report-recipients-table tr {
  cursor: pointer;
}

.assets-table td,
.accounts-table td,
.account-makers-table td,
.account-report-recipients-table td {
  min-width: 140px;
}

.table tr.selected {
  background: var(--accent-faint);
}

.red-border {
  border-color: red !important;
  outline-color: red;
}
.green-border {
  border-color: green !important;
  outline-color: green;
}

.mobile-only {
  display: none;
}

.scrollbar-content {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
}

.scrollbary-track {
  background: transparent;
  width: 6px !important;
  top: 0 !important;
  height: 100% !important;
}

.scrollbarx-track {
  background: transparent;
  width: 100% !important;
  left: 0 !important;
  height: 6px !important;
}

.scrollbar-thumbnail {
  background: #d8d8d8 !important;
  transition: opacity 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.blur {
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 1);
  user-select: none;
}

.form-group {
  margin-bottom: 2rem;
}

.form-group-centered {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

/* summary table - properties/accounts */
.site-summary-table {
  margin-left: 5rem;
}
.site-summary-table th {
  padding-right: 50px;
  vertical-align: top;
  text-align: left;
}
.site-summary-table td {
  word-break: break-all;
  word-wrap: break-word;
  padding-right: 10px;
  vertical-align: top;
}
@media (max-width: 768px) {
  .site-summary-table {
    margin-left: 0;
    border-radius: 10px;
  }
  .site-summary-table tbody,
  .site-summary-table {
    display: block;
    width: 100%;
  }
  .site-summary-table tr {
    display: block;
    display: flex;
    justify-content: space-between;
    margin: 0 5px;
    padding: 10px 0;
    border-bottom: 2px dotted var(--border-separator);
  }
  .site-summary-table tr:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
  .site-summary-table th {
    font-weight: normal;
    display: block;
    padding-right: 0;
    opacity: 0.7;
  }
  .site-summary-table th::after {
    content: ":";
  }
  .site-summary-table td {
    font-weight: normal;
    display: block;
    word-break: normal;
    text-align: right;
  }
}

/* inputs widths */
.input-tiny {
  width: 95px;
}
.input-short {
  width: 160px;
}
.input-normal {
  width: 255px;
}
.input-semiwide {
  width: 300px;
}
.input-wide {
  width: 350px;
}
.input-wider {
  width: 400px;
}
.input-widest {
  width: 600px;
}
.input-huge {
  width: 840px;
}
.input-block {
  width: 100%;
}
.input-grow {
  flex-grow: 1;
}

/* capped widths*/
.input-tiny-cap {
  flex-basis: 80px;
  flex-grow: 0;
  flex-shrink: 1;
}
.input-short-cap {
  flex-basis: 160px;
  flex-grow: 0;
  flex-shrink: 1;
}
.input-normal-cap {
  flex-basis: 255px;
  flex-grow: 0;
  flex-shrink: 1;
}
.input-semiwide-cap {
  flex-basis: 300px;
  flex-grow: 0;
  flex-shrink: 1;
}
.input-wide-cap {
  flex-basis: 350px;
  flex-grow: 0;
  flex-shrink: 1;
}
.input-wider-cap {
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 1;
}
.input-widest-cap {
  flex-basis: 600px;
  flex-grow: 0;
  flex-shrink: 1;
}

@media (max-width: 768px) {
  .input-tiny-cap {
    width: 95px;
  }
  .input-short-cap {
    flex-basis: auto;
  }
  .input-normal-cap {
    flex-basis: auto;
  }
  .input-semiwide-cap {
    flex-basis: auto;
  }
  .input-wide-cap {
    flex-basis: auto;
  }
  .input-wider-cap {
    flex-basis: auto;
  }
  .input-widest-cap {
    flex-basis: auto;
  }
}

/* remove autofill color */
/* firefox */
input:autofill {
  background-color: transparent !important;
  filter: none !important;
}
:-moz-autofill,
:-moz-autofill-preview {
  filter: none !important;
  background: transparent !important;
}
input:hover:not(:focused):autofill {
  background-color: transparent !important;
  filter: none !important;
}
:-moz-autofill:hover:not(:active),
:-moz-autofill-preview:hover:not(:active) {
  filter: none !important;
  background: transparent !important;
}

/* webkit */
@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  transition: background-color 1e9s ease-in-out 0s;
  caret-color: var(--text);
  box-shadow: 0 0 0 100px var(--background-elements) inset !important;
  -webkit-text-fill-color: var(--text) !important;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

@media (max-width: 768px) {
  .mobile-only {
    display: initial;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-75 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.75;
  }
}

@keyframes fade-in-50 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fade-out-50 {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 0.5;
  }
}
