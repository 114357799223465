.icon-component {
  height: 100%;
  width: 100%;
}

.IconStar,
.IconMap,
.IconChart {
  width: 60px;
}
