$fonts: (
  "small": 0.8rem,
  "normal": 1rem,
  "input": 1.1rem,
  "large": 1.3rem,
  "huge": 1.6rem,
  "enourmous": 2rem,
  "gigantic": 3rem,
);

@each $fontSizeName, $fontSize in $fonts {
  .font-#{$fontSizeName} {
    font-size: $fontSize !important;
  }
}

.font-light {
  font-weight: lighter;
}
.font-bold {
  font-weight: bold;
}
.ta-c {
  text-align: center;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}
